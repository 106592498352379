import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Contact Section */}
      <div className="contact-container">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl md:text-5xl font-bold text-center mb-8"
        >
          {t("contact.title")}{" "}
        </motion.h2>
        <p className="contact-description mobile-hidden">
          {t("contact.description")}
        </p>

        {/* Contact Form */}
        <form
          action="https://formspree.io/f/xnnaykzd" // Replace with your own Formspree form ID
          method="POST"
          className="contact-form"
        >
          <div className="form-group">
            <label htmlFor="name">{t("contact.form.name_label")}</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={t("contact.form.name_placeholder")}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">{t("contact.form.email_label")}</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t("contact.form.email_placeholder")}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">{t("contact.form.message_label")}</label>
            <textarea
              id="message"
              name="message"
              rows="6"
              placeholder={t("contact.form.message_placeholder")}
              required
            ></textarea>
          </div>

          <button type="submit" className="contact-btn">
            {t("contact.form.submit_button")}
          </button>
        </form>

        <footer className="footer">
          {/* Contact Info */}
          <div className="contact-info">
            <p>
              Email: <a href="mailto:renzo@startupp.ai">renzo@startupp.ai</a> |
              LinkedIn:{" "}
              <a
                href="https://www.linkedin.com/in/renzo-dupont-b9797941/"
                target="_blank"
                rel="noopener noreferrer"
              >
                My LinkedIn
              </a>
            </p>
          </div>
          <div className="footer-content">
            <p>&copy; {new Date().getFullYear()} All rights reserved.</p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
