import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay } from "swiper/modules"; // Import from modules
import "swiper/css";
import "swiper/css/effect-coverflow";

import example1 from "./example1.jpg";
import design2 from "./design2.png";
import design3 from "./design3.png";
import design4 from "./design4.png";
import design5 from "./design5.png";
import design6 from "./design6.png";
import design7 from "./design7.png";
import design8 from "./design8.png";
import { useTranslation } from "react-i18next";

const IntelligentWebDevelopment = () => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(null);
  const designs = [
    {
      image: example1,
      href: "https://www.credilit.com.uy",
      alt: t("intelligent_web_development.projects.credilit_sa"),
      hoverText: t("intelligent_web_development.hover_texts.credilit_sa"),
    },
    {
      image: design2,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design3,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design4,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design5,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design6,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design7,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
    {
      image: design8,
      href: "#",
      alt: t("intelligent_web_development.projects.portfolio_design"),
      hoverText: t("intelligent_web_development.hover_texts.portfolio_design"),
    },
  ];

  return (
    <section className="py-12 ">
      <div className="intelligent-web-development strong">
        <div className="mx-auto px-4">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-3xl md:text-5xl font-bold text-center mb-8"
          >
            {t("intelligent_web_development.title")}{" "}
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, duration: 0.8 }}
            className="text-lg md:text-xl text-center mb-12 leading-relaxed"
          >
            {t("intelligent_web_development.description")}
          </motion.p>

          <Swiper
            effect={"coverflow"}
            grabCursor={false}
            centeredSlides={true}
            slidesPerView={"auto"} // Default for smaller screens - adjust if needed
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 3,
              slideShadows: true,
            }}
            pagination={false}
            navigation={false}
            modules={[EffectCoverflow, Autoplay]}
            className="mySwiper"
            loop={true}
            autoplay={{
              delay: 1000, // Autoplay delay in milliseconds
              disableOnInteraction: false, // Set to true to pause on user interaction
            }}
            breakpoints={{
              // Add breakpoints here!
              768: {
                // At 768px and larger
                slidesPerView: 8,
              },
            }}
          >
            {designs.map((design, index) => (
              <SwiperSlide key={index}>
                <motion.a
                  href="#"
                  rel="noreferrer"
                  className="relative group overflow-hidden rounded-lg"
                  onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  <motion.img
                    src={design.image}
                    alt={design.alt}
                    className="w-full h-64 rounded-lg object-cover transition-transform duration-500 transform hover:scale-105"
                    style={{ height: "40vh" }}
                  />
                  <AnimatePresence>
                    {isHovered === index && (
                      <motion.div
                        className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <p className="text-white text-xl font-bold">
                          {design.hoverText}
                        </p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default IntelligentWebDevelopment;
