// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";
import { getLanguageFromDomain } from "./languageDetector";

// Define the resources
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

// Detect language synchronously
const hostname = window.location.hostname;
const language = getLanguageFromDomain(hostname) || "en"; // default to 'en' if undefined

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: language, // Set the detected language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    react: {
      useSuspense: false, // Disable suspense if not using it
    },
  });

export default i18n;
