// src/App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Resources/Home";
import Contact from "./Resources/Contact";
import About from "./Resources/About";
import TopPromptsForManagers from "./Resources/Blog/Top5ForManagers";
import ChatGPTIntro from "./Resources/Blog/ChatGPTIntro";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import "./i18n"; // Ensure i18n is imported

function App() {
  const { i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/blog/top-5-prompts-for-managers"
          element={<TopPromptsForManagers />}
        />
        <Route
          path="/blog/maximizing-daily-productivity-with-chatgpt"
          element={<ChatGPTIntro />}
        />
      </Routes>
    </>
  );
}

export default App;
