import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Professional = () => {
  const { t } = useTranslation();
  return (
    <div className="experience-container">
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-3xl md:text-5xl font-bold text-center mb-8"
      >
        {t("professional.title")}
      </motion.h2>
      <div className="timeline">
        {/* Timeline Item */}
        <div className="timeline-item">
          <div className="timeline-content">
            <h3>{t("professional.jobs.0.title")}</h3>
            <h4>{t("professional.jobs.0.company")}</h4>
            <p>{t("professional.jobs.0.description")}</p>
          </div>
        </div>

        {/* Timeline Item */}
        <div className="timeline-item">
          <div className="timeline-content">
            <h3>{t("professional.jobs.1.title")}</h3>
            <h4>{t("professional.jobs.1.company")}</h4>
            <p>{t("professional.jobs.1.description")}</p>
          </div>
        </div>

        {/* Timeline Item */}
        <div className="timeline-item">
          <div className="timeline-content">
            <h3>{t("professional.jobs.2.title")}</h3>
            <h4>{t("professional.jobs.2.company")}</h4>
            <p>{t("professional.jobs.2.description")}</p>
          </div>
        </div>

        {/* Timeline Item */}
        <div className="timeline-item mobile-hidden">
          <div className="timeline-content">
            <h3>{t("professional.jobs.3.title")}</h3>
            <h4>{t("professional.jobs.3.company")}</h4>
            <p>{t("professional.jobs.3.description")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Professional;
