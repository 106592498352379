// src/components/SEO.js
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const SEO = () => {
  const { t, i18n } = useTranslation();

  const baseUrls = {
    en: "https://startupp.ai",
    es1: "https://benjaminlabs.com",
    es2: "https://greenfenix.com.uy",
  };

  // Determine current language and corresponding canonical URL
  const language = i18n.language;
  let canonical = "";
  if (language === "es") {
    canonical = baseUrls.es1 + "/about";
  } else if (language === "es-uy") {
    canonical = baseUrls.es2 + "/about";
  } else {
    canonical = baseUrls.en + "/about";
  }

  const hreflangs = [
    { href: baseUrls.en + "/about", lang: "en" },
    { href: baseUrls.es1 + "/about", lang: "es" },
    { href: baseUrls.es2 + "/about", lang: "es-uy" },
  ];

  return (
    <Helmet>
      <title>{t("about_title")}</title>
      <meta name="description" content={t("about_description")} />
      {/* Canonical Tag */}
      {canonical && <link rel="canonical" href={canonical} />}
      {/* Hreflang Tags */}
      {hreflangs &&
        hreflangs.map((hreflang) => (
          <link
            key={hreflang.href}
            rel="alternate"
            href={hreflang.href}
            hreflang={hreflang.lang}
          />
        ))}
      {/* Structured Data can be added here or in a separate component */}
    </Helmet>
  );
};

export default SEO;
