import React from "react";
import SEO from "./SEO";
import "../App.css";
import Menu from "./Menu";
import Footer from "./Footer";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

function Contact() {
  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: (i = 1) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.6,
      },
    }),
  };

  const { t } = useTranslation();

  return (
    <>
      <SEO />
      <Menu />
      <br />
      <br />
      <motion.h1
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
        custom={2}
      >
        {t("about.title")}
      </motion.h1>
      <Footer />
    </>
  );
}

export default Contact;
