// Home.js
import React, { useState, forwardRef, useEffect, useCallback } from "react";
import SEO from "./SEO";
import "../App.css";
import Slideshow from "./Slideshow";
import AboutSection from "./AboutSection";
import img1 from "./bk1.jpg";
import img2 from "./slide2.webp";
import img3 from "./bk3.jpg";
import { motion } from "framer-motion";
import Attribution from "./Attribution";
import Menu from "./Menu";
import Footer from "./Footer";
import AINotifications from "./AINotifications";
import AIEasyImplementation from "./AIEasyImplementation";
import IntelligentWebDevelopment from "./IntelligentWebDevelpment";
import Professional from "./Professional";
import { useTranslation, Trans } from "react-i18next";
import ReactPageScroller from "react-page-scroller"; // Import the library

const Home = forwardRef((props, ref) => {
  // Wrap Home in forwardRef
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  const goToPage = useCallback(
    (pageNumber) => {
      // Wrap in useCallback
      console.log(ref);
      if (ref && ref.current) {
        ref.current.goToPage(pageNumber);
      }
    },
    [ref]
  );

  useEffect(() => {
    // Check if currentPage is a number AND ref.current is available
    if (typeof currentPage === "number" && ref && ref.current) {
      goToPage(currentPage);
    }
  }, [currentPage, ref, goToPage]); // Include ref in dependency array

  return (
    <>
      <SEO />
      <Menu goToPage={goToPage} />

      <ReactPageScroller
        className="react-page-scroller"
        pageOnChange={handlePageChange}
        customPageNumber={currentPage}
        ref={ref} // If the library uses refs for navigation
      >
        {/* Background Parallax Effect (adjust as needed for page scrolling) */}
        <div className="slide" id="home">
          {" "}
          {/* Set a fixed height for each "page" */}
          {/* Slide 1: Slideshow */}
          <Slideshow>
            <div className="slideshow-slide" key={1}>
              <img src={img1} alt={t("home.slideshow.slide1.image_alt")} />
              <div className="text-overlay">
                <motion.h1
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.6 }}
                >
                  {t("home.slideshow.slide1.title")}
                </motion.h1>
                <p>
                  <Trans
                    i18nKey="home.slideshow.slide1.description"
                    components={{ strong: <strong /> }}
                  />
                </p>
                <br />
                <br />
                <a
                  className="contact-btn"
                  style={{ marginRight: 10 }}
                  href="/about"
                >
                  {t("home.slideshow.slide1.buttons.about_me")}
                </a>
                <a className="contact-btn" href="/contact">
                  {t("home.slideshow.slide1.buttons.get_more_info")}
                </a>
              </div>
            </div>

            <div className="slideshow-slide" key={2}>
              <img src={img2} alt={t("home.slideshow.slide2.image_alt")} />
              <div className="text-overlay">
                <motion.h1
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.6 }}
                >
                  {t("home.slideshow.slide2.title")}
                </motion.h1>{" "}
                <p>{t("home.slideshow.slide2.description")}</p>
                <br />
                <br />
                <a
                  href="/about"
                  className="contact-btn"
                  style={{ marginRight: 10 }}
                >
                  {t("home.slideshow.slide2.buttons.get_more_info")}
                </a>
                <a
                  className="contact-btn"
                  href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
                >
                  {t("home.slideshow.slide2.buttons.about_me")}
                </a>
              </div>
            </div>

            <div className="slideshow-slide" key={3}>
              <img src={img3} alt={t("home.slideshow.slide3.image_alt")} />
              <div className="text-overlay">
                <motion.h1
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.6 }}
                >
                  {t("home.slideshow.slide3.title")}
                </motion.h1>{" "}
                <p>{t("home.slideshow.slide3.description")}</p>
                <br />
                <br />
                <a
                  className="contact-btn"
                  style={{ marginRight: 10 }}
                  href="https://www.linkedin.com/in/renzo-dupont-b9797941/details/recommendations/"
                >
                  {t("home.slideshow.slide3.buttons.get_more_info")}
                </a>
                <a className="contact-btn" href="/about">
                  {t("home.slideshow.slide3.buttons.about_me")}
                </a>
              </div>
            </div>
          </Slideshow>
        </div>

        {/* About Section */}
        <div className="slide" id="about">
          <motion.div
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <AboutSection />
          </motion.div>
        </div>

        {/* Intelligent Web Development Section */}
        <div className="slide" id="services">
          <motion.div
            initial={{ opacity: 0, x: 60 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <IntelligentWebDevelopment />
          </motion.div>
        </div>

        <div className="slide">
          <motion.div
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <AINotifications />
          </motion.div>
        </div>

        {/* AI Easy Implementation Section */}
        <div className="slide">
          <motion.div
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <AIEasyImplementation />
          </motion.div>
        </div>

        {/* Professional Experience Section */}
        <div className="slide" id="experience-timeline">
          <motion.div
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Professional />
          </motion.div>
        </div>

        {/* Attribution Section */}
        <div className="slide">
          <motion.div
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Attribution />
          </motion.div>
        </div>

        {/* Page 8: Footer/Contact (If it's a full page) */}
        <div className="slide">
          <Footer />
        </div>
      </ReactPageScroller>
    </>
  );
});

export default Home;
