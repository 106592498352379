import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";

const Slideshow = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust as needed
    fade: true,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const [shinePosition, setShinePosition] = useState(0);
  const shineDuration = 5000; // Duration of the shine animation in ms
  const shineInterval = 10000; // Time between shine animations in ms

  useEffect(() => {
    // Shine animation loop
    const shineTimer = setInterval(() => {
      setShinePosition(0); // Reset shine to start position
      setTimeout(() => {
        // Delay to allow shine to restart from beginning
        setShinePosition(-100); // Move shine off-screen
      }, shineDuration);
    }, shineInterval);

    return () => clearInterval(shineTimer);
  }, []);

  return (
    <div className="slideshow" alt={currentSlide}>
      <Slider {...settings}>
        {React.Children.map(children, (child, index) => (
          <div key={index}>
            <AnimatePresence initial={true} custom={index}>
              <motion.div // Slide container
              // ... (existing motion.div code)
              >
                <div
                  className="slide-content"
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  {" "}
                  {/* Container for image and shine */}
                  {child}{" "}
                  {/* Your existing slide content (image, text, etc.) */}
                  <motion.div // Shine effect
                    className="shine-effect"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: `${shinePosition}%`,
                      width: "200%", // Shine is twice as wide as the image
                      height: "100%",
                      backgroundImage:
                        "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Adjust shine color/opacity
                      transform: "skewX(-20deg)", // Angle the shine
                      pointerEvents: "none", // Important for click-through
                      zIndex: 2, // Ensure shine appears on top
                    }}
                    animate={{ left: "-100%" }} // Animate off-screen
                    transition={{
                      duration: shineDuration / 1000,
                      ease: "linear",
                      repeat: Infinity,
                    }}
                  ></motion.div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slideshow;
