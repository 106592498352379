// Testimonials.js
import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Testimonials() {
  const { t } = useTranslation();
  const testimonialsData = t("testimonials.testimonials_data", {
    returnObjects: true,
  });

  return (
    <section className="testimonials">
      <div className="testimonials-grid">
        {testimonialsData.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <FaQuoteLeft className="quote-icon left" />
            <p className="testimonial-quote">{testimonial.quote}</p>
            <FaQuoteRight className="quote-icon right" />
            <div className="testimonial-info">
              <h3 className="testimonial-name">{testimonial.name}</h3>
              <p className="testimonial-title">{testimonial.title}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
