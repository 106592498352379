// AIEasyImplementation.js
import React from "react";
import { motion } from "framer-motion";
import screenshot from "./Salesforce.jpg"; // Replace with your actual screenshot
import { useTranslation } from "react-i18next";

const AIEasyImplementation = () => {
  const { t } = useTranslation();
  const services = t("ai_easy_implementation.services", {
    returnObjects: true,
  });

  return (
    <section className="py-12 ">
      {/* Dark background */}
      <div className="mx-auto px-4">
        <div className="text-center mb-8">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-3xl md:text-5xl font-bold strong"
          >
            {t("ai_easy_implementation.title")}
          </motion.h1>
          <p className="mt-4 text-lg text-gray-400 mobile-hidden">
            {t("ai_easy_implementation.description")}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {/* Cards Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className="service-card ai rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 p-6 flex flex-col relative overflow-hidden"
                whileHover={{ scale: 1.05, y: -10 }}
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <h3 className="services-slider-card-title text-2xl md:text-4xl font-bold mb-4 strong">
                  {service.title}
                </h3>
                <p className="services-slider-card-description text-lg text-gray-400 text-center">
                  {service.description}
                </p>
              </motion.div>
            ))}
          </div>

          {/* Screenshot Section */}
          <motion.div
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <img
              src={screenshot}
              alt={t("ai_easy_implementation.image_alt")}
              className="rounded-lg shadow-md w-full group"
            />
          </motion.div>
        </div>

        {/* Call to Action */}
        {/* ... */}
      </div>
    </section>
  );
};

export default AIEasyImplementation;
